//import Vue from 'vue'
//import VueRouter from 'vue-router'




import { createWebHistory, createRouter } from "vue-router";




  
  var routes = [


   

    {
      path: '/',
      name: 'Subdomain',
      component: () => import('../views/WordSearchHome.vue')
      },

     
     
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
        },


        {
          path: '/Benefits',
          name: 'Benefits',
          component: () => import('../views/Benefits.vue')
          },
     

 


      {
        path: '/wordsearch/:category(.*)/:subcategory(.*)/:title(.*)',
        name: 'Word Search Title',
        component: () => import('../views/WordSearch.vue'),
       
      },


      {
        path: '/tiktok/:category(.*)/:subcategory(.*)/:title(.*)',
        name: 'Word Search TikTok',
        component: () => import('../views/TikTok.vue'),
       
      },

      
      {
        path: '/tiktok2/:category(.*)/:subcategory(.*)/:title(.*)',
        name: 'Word Search TikTok 2',
        component: () => import('../views/TikTok2.vue'),
       
      },

   

      {
        path: '/userpuzzle/:uniqueid(.*)/:title(.*)',
        name: 'User Word Search',
        component: () => import('../views/WordSearch.vue'),
       
      },

      {
        path: '/userpuzzle/:uniqueId(.*)/:title(.*)/print',
        name: 'Print Me',
        component: () => import('../views/Print.vue'),
       
      },



      {
        path: '/wordsearch/:category(.*)/:subcategory(.*)',
        name: 'Word Search Sub Categories',
        component: () => import('../views/WordSearchSubCategories.vue'),
       
      },

     

      {
        path: '/wordsearch/:category(.*)',
        name: 'Word Search Categories',
        component: () => import('../views/WordSearchCategories.vue'),
       
        },

        {
          path: '/wordsearch',
          name: 'Subdomain',
          component: () => import('../views/WordSearchHome.vue')
          },


          {
            path: '/word-search-maker',
            name: 'Subdomain',
            component: () => import('../views/WordSearchMaker.vue')
            },

       

      

       
       


    {
      path: '/90sMoviesVolume1',
      name: '90sMoviesVolume1',
      component: () => window.location.replace("https://www.amazon.com/review/create-review/?asin=B0BTNNPF6X")
      },


   
    
  

     

      

      {
        path: '/wordsearch/:category(.*)/:subcategory(.*)/:title(.*)/print',
        name: 'Print',
        component: () => import('../views/Print.vue'),
       
      },


      
      {
        //path: '*',
        path: "/:catchAll(.*)",
        name: '404',
        component: () => import('../views/WordSearchHome.vue')
        
      
      },
      



    
  ]









const router = new createRouter({
//const router = new VueRouter({
  history: createWebHistory(),
  props: true,
  routes
})

/*
router.beforeEach((to, from, next) => {
  document.title = document.location.href.split('/')[document.location.href.split('/').length - 1] + " Word Search"
  next();
});
*/

export default router
